/*!
 * ReformSlider
 * Version 1.0.0
 * Part of the 80 Days Reform Pattern Library
 * http://www.eighty-days.com
 * 
 *
 *
 */
 

(function($){
    $.fn.reformSlider = function (options) {
        var settings = $.extend({
            speed: 2000,
            animationSpeed: 500,
            beforeChange: function () {},
            afterChange: function () {},
            onLoad: function () {},
            arrows: false,
            pagination: false,
            paginationArrows: false,
            autoplay: true
        }, options);
        
        
        
        


        return this.each(function(){
        
            var $this = $(this);
            
            var gotoSlideNumber;
            
                
            
            this.triggerChangeCallbacks = function() {
                console.log('TRIGGERED');
                if (settings.beforeChange) {
                    settings.beforeChange.call(this, this);
                }
                
                if (settings.afterChange) {
                    setTimeout( function () {
                        settings.afterChange.call(this, this);
                    }, settings.animationSpeed + settings.animation);
                }
            }
            
            
            this.updatePagination = function() {
                var currentSlideIndex = $this.find('.reform-slide').index($('.reform-slide-current'));
                $this.find('.reform-pagination li').removeClass('active');
                $($this.find('.reform-pagination li').get(currentSlideIndex + 1)).addClass('active');
            }
            
            
            
            
            
            
            
            
            // Fade to a specific slide number (counting starts at 1)
            this.gotoSlide = function() {
                console.log('Going to slide');
                $this.find('.reform-slide').finish();
                
                var slideIndex = parseInt(gotoSlideNumber, 10) - 1;
                
                var $currentSlide = $this.find('.reform-slide-current');
                var currentSlideIndex = $this.find('.reform-slide').index($this.find('.reform-slide-current'));
                
                // Do nothing if already on that slide
                if ( slideIndex === currentSlideIndex ) {
                    return;
                }
                
                $this.find('.reform-slide').eq(slideIndex).show();
                
                $currentSlide.fadeOut(options.animationSpeed, function () {
                   $currentSlide.removeClass('reform-slide-current').hide();
                   $this.find('.reform-slide').eq(slideIndex).addClass('reform-slide-current');
                   $this.updatePagination;
                });
                
                $this.triggerChangeCallbacks;
            }
            
            
                
                
            // Handle clicks on the pagination buttons 
            this.paginationClicked = function() {
                var buttonText = $(this).text();
                
                if (buttonText === 'Prev') {
                    $this.previousSlide;
                } else if (buttonText === 'Next') {
                    $this.nextSlide;
                } else {
                    gotoSlideNumber = buttonText;
                    console.log('About to go to slide', gotoSlideNumber);
                    $this.gotoSlide;
                }
            }
            
        
        
        
            // Fade to the next slide
            this.nextSlide = function() {
                $this.find('.reform-slide').finish();
                
                var $currentSlide = $this.find('.reform-slide-current');

                if ( $currentSlide.next().length > 0 ) { // Check if there are more slides after this...
                    $currentSlide.next().show();
                    
                    $currentSlide.fadeOut(settings.animationSpeed, function () {
                       $currentSlide.removeClass('reform-slide-current').hide();
                       $currentSlide.next().addClass('reform-slide-current');
                       this.updatePagination;
                    });
                } else { // ... if not loop back to the first slide.
                    $currentSlide.siblings().first().show();
                    
                    $currentSlide.fadeOut(settings.animationSpeed, function () {
                        $currentSlide.removeClass('reform-slide-current').hide();
                        $currentSlide.siblings().first().addClass('reform-slide-current');
                        this.updatePagination;
                    });
                }
                
                this.triggerChangeCallbacks;
            }
            
            
            // Fade to the previous slide
            this.previousSlide = function() {
                $this.find('.reform-slide').finish();
                
                var $currentSlide = $this.find('.reform-slide-current');
                
                if ( $currentSlide.prev().length > 0 ) { // Check if there are more slides before this...
                    $currentSlide.prev().show();
                    
                    $currentSlide.fadeOut(settings.animationSpeed, function () {
                       $currentSlide.removeClass('reform-slide-current').hide();
                       $currentSlide.prev().addClass('reform-slide-current');
                       this.updatePagination;
                    });
                } else { // ... if not loop back to the last slide.
                    $currentSlide.siblings().last().show();
                    
                    $currentSlide.fadeOut(settings.animationSpeed, function () {
                        $currentSlide.removeClass('reform-slide-current').hide();
                        $currentSlide.siblings().last().addClass('reform-slide-current');
                        this.updatePagination;
                    });
                }
                
                this.triggerChangeCallbacks;			
            }
            
            
            
            
            
            
            
            
            
            
            
            
                    
            // Fire the onLoad callback once all's set up
            if (settings.onLoad) {
                settings.onLoad.call(this, this);
            }
            
            // Set the init classes
            $this.addClass('reform-slider-initialized');
            $this.children().addClass('reform-slide').hide();
            $this.children().first().addClass('reform-slide-current').show();
            
            var slideCount = $this.children('.reform-slide').length;
            
            // Add the arrows?
            if ((slideCount > 1) && (settings.arrows === true)) {
                $this.after('<ul class="reform-slider-arrows"><li><a href="#" class="reform-prev">Previous</a></li><li><a href="#" class="reform-next">Next</a></li></ul>');
                
                var sliderObject = this;
                $this.next().find('.reform-prev').on('click', function () { sliderObject.previousSlide(); return false; });
                $this.next().find('.reform-next').on('click', function () { sliderObject.nextSlide(); return false; });
            }
            
            // Add the pagination?
            if ( (settings.pagination === true) && (slideCount > 1) ) {
                var paginationHTMLString = '<ul class="reform-pagination">';
                
                if (settings.paginationArrows === true) {
                    paginationHTMLString += '<li class="reform-pagination-prev"><button>Prev</button></li>';
                }
                
                for (var i=0; i < slideCount; i++) {
                    paginationHTMLString += '<li class="reform-pagination-dot"><button>' + (i + 1*1) + '</button></li>';
                }
                
                if (settings.paginationArrows === true) {
                    paginationHTMLString += '<li class="reform-pagination-next"><button>Next</button></li>';
                }
                
                paginationHTMLString += '</ul>';
                $this.after(paginationHTMLString);
                $this.next('.reform-pagination').find('.reform-pagination-dot').first().addClass('active');
                
                $this.next('.reform-pagination').find('button').on('click', this.paginationClicked);
            }
            
            
            // Get rollin'
            if ( (slideCount > 1) && (settings.autoplay)) {
                setInterval(this.nextSlide, settings.speed);
            } else {
                console.log('ReformSlider: 1 or fewer slides found');
            }
            
            
            /*var currentValue = $this.text();
            var newValue = '';
            var i = 0;
            
            for (i = 0; i < currentValue.length; i++) {
                if (settings.action === 'scramble') {
                    newValue = newValue + String.fromCharCode(currentValue.charCodeAt(i) + 1);
                }
                else if (settings.action == 'unscramble') {
                    newValue = newValue + String.fromCharCode(currentValue.charCodeAt(i) - 1);
                }
            }
            $this.text(newValue).css('color', settings.action === 'scramble' ? settings.scrambleColor : settings.unscrambleColor);*/
        
        });
    };
})(jQuery);