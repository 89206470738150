var mapStyle = projectSettings.mapStyle;

$(window).on('DOMContentLoaded load resize scroll', animateMapsInView);
animateMapsInView();

$('[data-map]').each(function () {
	buildMap(this);
});

function animateMapsInView() {
	$('[data-map-load-on-view]').each(function () {
		
		if ( $(this).hasClass('in-view') ) {
			return;	
		}
		
		if ( isElementInViewport($(this)) ) {
			var elementToAnimate = this;
			
			$(elementToAnimate).addClass('in-view'); // log that this element has been seen
			
			
			setTimeout(function () {
				$(elementToAnimate).addClass( $(elementToAnimate).data('animate-in-view') ); // apply the animation class
			}, 300);
			
			
			if ( typeof $(elementToAnimate).data('map-load-on-view') != 'undefined' ) { // only load this map once it's in view
				buildMap(elementToAnimate);
			}
		}
	});
}

function isElementInViewport (el) {
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}
	
	var rect = el.getBoundingClientRect();
	
	var t = rect.y;
	var b = window.innerHeight - rect.y - rect.height;
	var h = window.innerHeight;
	
	if (
		( (t >= 0) && (t <= h) ) ||
		( (b >= 0) && (b <= h) )
	) {
		return true;
	} else {
		return false;
	}
}


function buildMap(theMap) {
	$(theMap).each(function () {
		var map, centerLat, centerLng, zoom, marker, markerLat, markerLng;
		
		if (typeof $(this).data('map-center-coords') != 'undefined') {
			centerLat = parseFloat( $(this).data('map-center-coords').split(',')[0] );
			centerLng = parseFloat( $(this).data('map-center-coords').split(',')[1] );
		} else {
			centerLat = 0;
			centerLng = 0;
		}
		
		// Center the map on the marker when on mobile (i.e. ignore the specified centre coords)
		if (typeof $(this).data('map-marker-coords') !== 'undefined') {
			if ( window.innerWidth <= 640 ) {
				centerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				centerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			}
		}
		
		zoom = $(this).data('map-zoom') || 8;
		
		
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: mapStyle
			});
		
		
	
		if (typeof $(this).data('map-marker-coords') != 'undefined') {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}
			
			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});
			
			if (typeof $(this).data('map-marker-url') != 'undefined') {
				marker.setIcon($(this).data('map-marker-url'));
			}
		}
		
		
	
		
		$(this).siblings('[data-map-marker]').each(function () {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}
			
			var locationMarker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});
			
			locationMarker.setIcon($(this).data('map-marker-url') || '/assets/img/map-marker.svg');

		});
		
		
		
		
	});
}