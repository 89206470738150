initGridHelper();

function initGridHelper() {
	var holdingShift = false;
	var mouseDown = false;
	var squareStartX = 0;
	var squareStartY = 0;
	var squareWidth = 0;
	var squareHeight = 0;
	
	// Restore previous state on load
	if ( getCookie('guides_visible') === 'Y' ) {
		addGuides();
	}
	
	// Keyboard events
	$('body').on('keydown', function(e) {		
		if ( (e.key === '\'') && (e.metaKey) && (e.shiftKey) ) {
			if ( $('.guides').length === 0 ) {
				setCookie('guides_visible', 'Y', 1);
				addGuides();
			} else {
				setCookie('guides_visible', 'N', 1);
				removeGuides();
			}
		}
		
		if (e.shiftKey) {
			$('.guides__crosshair-x, .guides__crosshair-y').show();
			$('.guides').css('pointer-events', 'all').css('cursor', 'crosshair');
			holdingShift = true;
		}
	});
	
	$('body').on('keyup', function(e) {	
		if (!e.shiftKey) {
			$('.guides__crosshair-x, .guides__crosshair-y').hide();
			$('.guides').css('pointer-events', 'none').css('cursor', 'default');
			holdingShift = false;
		}
	});
	
	
	// Mouse events
	$('body').on('mousedown', function (e) {
		if (holdingShift) {
			e.preventDefault();
			
			mouseDown = true;
			
			squareStartX = e.originalEvent.x;
			squareStartY = e.originalEvent.y;
			
			$('.guides__square').css('left', squareStartX + 'px');
			$('.guides__square').css('top', squareStartY + 'px');
		}
	});
	
	$('body').on('mouseup', function (e) {
		if (holdingShift && mouseDown) {
			mouseDown = false;
		}
	});
	
	$('body').on('mousemove', function (e) {
		if (holdingShift && mouseDown) {
			squareWidth = e.originalEvent.x - squareStartX;
			squareHeight = e.originalEvent.y - squareStartY;
			
			var transWidth = 0;
			var transHeight = 0;
			
			if (squareWidth < 0) {
				transWidth = Math.abs(squareWidth);
				$('.guides__square').css('transform-origin', 'top right');
			} 
			
			if (squareHeight < 0) {
				transHeight = Math.abs(squareHeight);
				$('.guides__square').css('transform-origin', 'bottom left');
			} 
			
			if ((squareWidth < 0) && (squareHeight < 0)) {
				$('.guides__square').css('transform-origin', 'bottom right');
			}
			
			$('.guides__square').css('width', Math.abs(squareWidth) + 'px');
			$('.guides__square').css('height', Math.abs(squareHeight) + 'px');
			$('.guides__square').css('transform', 'translate(' + transWidth + 'px, ' + transHeight + 'px)');
			
			$('.guides__square-dims').html(Math.abs(squareWidth) + '&nbsp;×&nbsp;' + Math.abs(squareHeight));
		}
	});
	
	
	// Append the guide container and cell divs
	function addGuides() {
		// Main wrapper
		var guidesHTML = '<div class="guides" style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; z-index: 100000; pointer-events: none;"><div class="grid-container grid-x" style="height: 100vh"></div>';
		
		// Window size box
		guidesHTML += '<div class="guides__window" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 32px; font-weight: bold; text-align: center; color: #fff; padding: 10px 20px; background: rgba(0, 0, 0, 0.7); display: none;"><div class="guides__window-dimemsions"></div><div class="show-for-small-only">Small</div><div class="show-for-medium-only">Medium</div><div class="show-for-large">Large</div></div>';
		
		// Crosshairs
		guidesHTML += '<div class="guides__crosshair-x" style="position: absolute; top: 0; width: 1px; height: 100%; border-right: 1px dotted magenta; display: none;"></div>';
		guidesHTML += '<div class="guides__crosshair-y" style="position: absolute; left: 0; width: 100%; height: 1px; border-bottom: 1px dotted magenta; display: none;"></div>';
		
		// Measuring square
		guidesHTML += '<div class="guides__square" style="position: absolute; top: 0; left: 0; width: 0; height: 0; border: 1px solid green; background-color: #A7F59855;"><div class="guides__square-dims" style="position: absolute; top: 100%; left: 100%; font-size: 12px; font-family: sans-serif; color: #fff; padding: 5px; background: rgba(0, 0, 0, 0.7);"></div></div>'
		
		// Closing
		guidesHTML += '</div>';
		
		$('body').append(guidesHTML);
		for (i=0; i<12; i++) {
			if (i === 0) {
				$('.guides .grid-x').append('<div class="cell small-1" style="border: solid cyan; border-width: 0 1px;"></div>');
			} else {
				$('.guides .grid-x').append('<div class="cell small-1" style="border-right: 1px solid cyan;"></div>');
			}
		}
		
		$(window).on('resize.guides', function () {
			// add the current window dimensions
			var lastResize = new Date();
			
			$('.guides__window').show();
			$('.guides__window-dimemsions').text(window.innerWidth + ' × ' + window.innerHeight);
			
			var resizeChecker = setInterval(function () {
				var timeSinceLastResize = (new Date()) - lastResize;
				
				if ( timeSinceLastResize > 2000) {
					$('.guides__window').hide();
					clearInterval( resizeChecker );
				}
			}, 1000);
		});
		
		$(document).on('mousemove.guides', function (e) {			
			$('.guides__crosshair-x').css('left', e.originalEvent.x + 'px');
			$('.guides__crosshair-y').css('top', e.originalEvent.y + 'px');
		});
	}
	
	
	function removeGuides() {
		$('.guides').remove();
		$(window).off('resize.guides');
		$(document).off('mousemove.guides');
	}
	
	
	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		var expires = "expires="+ d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	
	
	function getCookie(cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for(var i = 0; i <ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
}