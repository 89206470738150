$(document).foundation();

$(document).ready(function () {
    // utils
	window['initVerticalCenter']();
	window['initAnimateInView']();
	window['inlineSVGs']();

	// elements
	window['initDropdownNav']();
	window['initHeader']();
	window['initDropdownOffers']();
	window['initAccordions']();
	window['initMaps']();
	window['initSlider']();
	window['initLandingSlider']();
	window['initFooterCarousel']();
	window['initFeaturedCarousel']();
	window['initDetail']();
	window['initOtherRooms']();
	window['initForms']();
	window['initTerms']();
	window['initGallery']();
	window['initTreatments']();
	window['initAccommodation']();
	window['initOffCanvas']();
	window['initPopup']();
	window['initLogoCarousel']();
	window['initHome']();
});





// Define the default settings to be used in the project
// These can be overridden in each sites settings.js file
var defaultSettings = {
	slider: {
		speed: 3500,
		animationSpeed: 750,
		arrows: true,
		pagination: true,
		paginationArrows: true
	},
	sliderFullHeightScrollButton: true,
	landingSlider: {
		autoplay: false,
		animationSpeed: 750,
		arrows: true,
		pagination: false,
		paginationArrows: false
	},
	footerCarousel: {
		slidesToShow: 5,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 2
				}
			}
		]
	},
	homeSlider: {
		fade: true,
		arrows: true
	},
	logoCarousel: {
		slidesToShow: 5,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 2
				}
			}
		]
	},
	otherRoomsCarousel: {
		arrows: false
	},
	featuredCarouselSingleImage: {
		arrows: true,
		dots: true,
		slidesToShow: 3,
		centerMode: true,
		centerPadding: '0px',
		responsive: [
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
	},
	featuredCarouselDoubleImage: {
		arrows: true,
		dots: true,
		slidesToShow: 1
	},
	accordion: {},
	inlineSVG: [
        '.footer__social img',
        '.header__mobile-call img'
    ],
	mapStyle: false
}
var settings;
if (typeof projectSettings === 'undefined') {
	settings = defaultSettings;
} else {
	// Update the default settings with the custom ones
	settings = $.extend(true,{}, defaultSettings, projectSettings);
}






var initVerticalCenter = function () {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}



// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
var inlineSVGs = function () {
    var selectorString = '';
    if (typeof settings.inlineSVG === "object") {
        selectorString = settings.inlineSVG.join(',');
    } else {
        selectorString = settings.inlineSVG;
    }

    $(selectorString).each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        
        if ((UrlExists(imgURL)) && (imgURL.indexOf('.svg') > -1)) {
	        $.get(imgURL, function (data) {
	            // Get the SVG tag, ignore the rest
	            var $svg = $(data).find('svg');
	
	            // Add replaced image's ID to the new SVG
	            if (typeof imgID !== 'undefined') {
	                $svg = $svg.attr('id', imgID);
	            }
	            // Add replaced image's classes to the new SVG
	            if (typeof imgClass !== 'undefined') {
	                $svg = $svg.attr('class', imgClass + ' replaced-svg');
	            }
	
	            // Remove any invalid XML tags as per http://validator.w3.org
	            $svg = $svg.removeAttr('xmlns:a');
	
	            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
	            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
	                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
	            }
	
	            // Replace image with new SVG
	            $img.replaceWith($svg);
	        }, 'xml');
        }
    });
}

function UrlExists(url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status!=404;
}

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}





// var wrapPrice = function (findIn, wrapBefore, wrapAfter) {
// 	// finds price strings like £100, or $400.50 
// 	return findIn.replace(/(((£|\$|€)([0-9]*)([.|,])([0-9]*)|(£|\$|€)([0-9]*)))/g, wrapBefore + '$1' + wrapAfter);
// }






/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
function coverViewport(element, scaleToParent) {
    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = $(element).parent().innerWidth();
        viewportHeight = $(element).parent().innerHeight();
    } else {
        viewportWidth = $(window).innerWidth();
        viewportHeight = $(window).innerHeight();
    }

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('left', '0');
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('left', '0');
        $element.css('top', '0');
    }
}




function pushContentBelowHeader() {
	$('main').css('padding-top', $('.header').outerHeight() + 20);
}



var initSlider = function () {
	$('.slider__inner').reformSlider(
		settings.slider
	);
		
	if ($('.slider__video').length > 0) {
		// Make sure the slider always fills the whole screen
		coverViewport($('.slider__video iframe'), true);
		$(window).on('resize', function () {
			coverViewport($('.slider__video iframe'), true);
		});
	}
	
	// Add a button for users to scroll down to the content below
	if (settings.sliderFullHeightScrollButton) {
		if ($('.slider').hasClass('slider--full-height')) {
			$('.slider').append('<div class="slider__scroll"><button>Scroll</button></div>');
		}
		$('.slider__scroll button').on('click', function () {
			$('html, body').animate({
				scrollTop: $('.slider').outerHeight() - $('.header').outerHeight()
			}, 1000);
			
			return false;
		});
	}
	
	// If there's no slider, make sure that the content starts below the header
	if ($('.slider').length === 0) {
		pushContentBelowHeader();
		$(window).on('resize', pushContentBelowHeader);
	}
}

	
var initLandingSlider = function () {	
	$('.landing__slider-inner').reformSlider(
		settings.landingSlider
	);
}



var initFooterCarousel = function () {	
	$('.footer__logo-carousel').slick(settings.footerCarousel);
}

var initFeaturedCarousel = function () {		
	$('.featured-carousel--has-scrollbar').append('<div class="featured-carousel__scrollbar"><div class="featured-carousel__scrollbar-thumb"></div></div>');
	
	var dragStarted = false;
	var dragStartMousePosition = 0;
	var dragStartThumbPosition = 0;
	var dragDistance = 0;

	$('.featured-carousel--has-scrollbar .featured-carousel__inner').on('init', function (event, slick) {
		setScrollbarThumbSize(this);
		$(window).on('resize', function () {
			setScrollbarThumbSize(this);
		});
		
		$carousel       = $(this);
		$scrollbar      = $(this).parent().find('.featured-carousel__scrollbar');
		$scrollbarThumb = $(this).parent().find('.featured-carousel__scrollbar-thumb');
		
		$scrollbarThumb.on('mousedown touchstart', function (event) {
			// Drag has started, so note the mouse starting postiion
			dragStarted = true;
			dragStartMousePosition = event.clientX;
			dragDistance = 0;
			$scrollbarThumb.css('transition-duration', '0s');
			dragStartThumbPosition = $scrollbarThumb.css('transform').split(/[()]/)[1].split(',')[4];
		});
		
		$(window).on('mouseup touchend', function (event) {
			if (!dragStarted) {
				return;
			}
			// Finished dragging, so clear temp vals and move the thumb to it's correct position
			dragStarted = false;
			$scrollbarThumb.css('transition-duration', '0.3s');
			
			var slideCount = $carousel.parent().find('.slick-dots li').length;
			var scrollbarWidth = $carousel.parent().find('.featured-carousel__scrollbar').outerWidth();
			var thumbWidth = scrollbarWidth / slideCount;
			var dragEndPosition = $scrollbarThumb.css('transform').split(/[()]/)[1].split(',')[4];
			
			var dragEndIndex = Math.floor((dragEndPosition / scrollbarWidth) * 10);
			if (dragEndIndex < 0) {
				dragEndIndex = 0;
			} else if (dragEndIndex >= slideCount) {
				dragEndIndex = slideCount-1;
			}
			
			// finally tell slick what slide to change to
			$carousel.slick('slickGoTo', dragEndIndex, false);
		});
		
		$(window).on('mousemove touchmove', function (event) {
			if (dragStarted) {
				dragDistance = event.clientX - dragStartMousePosition;
				var newPosition = (event.clientX - dragStartMousePosition) + dragStartThumbPosition*1;

				// Stop it from dragging outside the scrollbar
				if ((newPosition >= 0) && (newPosition < ($scrollbar.outerWidth() - $scrollbarThumb.outerWidth()))) {
					$scrollbarThumb.css('transform', 'translateX(' + (dragDistance + dragStartThumbPosition *1) + 'px)');
				}
			}
		});
	});
	
	$('.featured-carousel--has-scrollbar .featured-carousel__inner').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		setScrollbarThumbPosition(this, nextSlide);
	});
	
	$('.featured-carousel--single-image .featured-carousel__inner').slick(settings.featuredCarouselSingleImage);
	$('.featured-carousel--double-image .featured-carousel__inner').slick(settings.featuredCarouselDoubleImage);
	
	function setScrollbarThumbSize(carousel) {
		// Divide the width of the scrollbar by the number of slides to get the width of the thumb
		var slideCount = $(carousel).parent().find('.slick-dots li').length;
		var scrollbarWidth = $(carousel).parent().find('.featured-carousel__scrollbar').outerWidth();
		var thumbWidth = scrollbarWidth / slideCount;
		$(carousel).parent().find('.featured-carousel__scrollbar-thumb').css('width', thumbWidth);
	}
	
	function setScrollbarThumbPosition(carousel, nextSlideIndex) {
		// Move the thumb to the correct notch for the current slide index
		var slideIndex;
		if (typeof nextSlideIndex === 'undefined') {
			slideIndex = nextSlideIndex;
		} else {
			slideIndex = $(carousel).slick('slickCurrentSlide');
		}
		var scrollbarThumbWidth = $(carousel).parent().find('.featured-carousel__scrollbar-thumb').outerWidth();
		var thumbPosition = nextSlideIndex * scrollbarThumbWidth;
		$(carousel).parent().find('.featured-carousel__scrollbar-thumb').css('transform', 'translateX(' + thumbPosition + 'px)');
	}
}



var initAnimateInView = function () {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
	
	function animateElementsInView() {
		$('[data-animate-in-view]').each(function () {
			if ( isElementInViewport($(this)) ) {
				$(this).addClass( $(this).data('animate-in-view') );
			}
		});
	}
	
	// Modified version of:
	// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
	function isElementInViewport (el) {
	    //special bonus for those using jQuery
	    if (typeof jQuery === "function" && el instanceof jQuery) {
	        el = el[0];
	    }
	
	    var rect = el.getBoundingClientRect();
	    
	    return (
		    rect.top <=  $(window).scrollTop() + window.innerHeight
	    );
	}
}








var initDropdownNav = function () {
	$('.header__nav > ul > li').on('mouseenter', function (e) {
		if ($('html').attr('data-whatintent') === 'mouse') {
			$(this).find('.header__sub-nav').addClass('open');
		}
	});
	
	$('.header__nav > ul > li').on('mouseleave', function (e) {
		if ($('html').attr('data-whatintent') === 'mouse') {
			$(this).find('.header__sub-nav').removeClass('open');
		}
	});
	
	$('.header__nav > ul > li').on('touchstart', function (e) {
		var $dropdown = $(this).find('.header__sub-nav');
		if ( $dropdown.hasClass('open') ) {
			return; // menu has been pressed second time so allow to navigate there
		} else {
			$('.header__sub-nav.open').removeClass('open');
			$dropdown.addClass('open');
			e.preventDefault();
		}
	});
}




var initHeader = function () {
	
	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
	      deferTimer;
	  return function () {
	    var context = scope || this;
	
	    var now = +new Date,
	        args = arguments;
	    if (last && now < last + threshhold) {
	      // hold on to it
	      clearTimeout(deferTimer);
	      deferTimer = setTimeout(function () {
	        last = now;
	        fn.apply(context, args);
	      }, threshhold);
	    } else {
	      last = now;
	      fn.apply(context, args);
	    }
	  };
	}

	// Detect if the header is over and image slider, if yes then give it a transparent background
	setHeaderTransparency();
	$(window).on('scroll', setHeaderTransparency);

	function setHeaderTransparency() {
		if (window.pageYOffset < 150) {
			$('.header').addClass('header--transparent');
		} else {
			$('.header').removeClass('header--transparent');
		}
	}
	
	// Show the burger nav when there isn't enough room for the horizontal nav
	if ( $('.header__horizontal-nav').length > 0 ) {
		$(window).on('resize', function () {
			throttle(function (event) {
				console.log('resizing2');
			}, 1500);
		});
	}
}



var initDropdownOffers = function () {
	$('.dropdown-offers').each(function () {
		$(this).accordiom({
			showFirstItem: false
		});
		
		positionDropdownOffers();
		Foundation.onImagesLoaded($('.header img'), positionDropdownOffers);
		$(window).on('resize', positionDropdownOffers);
		
		function positionDropdownOffers() {
			$('.dropdown-offers').css('top', $('.header').outerHeight() + 'px');
		}
	});
}



var initAccordions = function () {
	$('.accordion').accordiom(settings.accordion);
}





var initMaps = function () {
	$('[data-map]').each(function () {
		var map, centerLat, centerLng, zoom, marker, markerLat, markerLng;
		
		if (typeof $(this).data('map-center-coords') != 'undefined') {
			centerLat = parseFloat( $(this).data('map-center-coords').split(',')[0] );
			centerLng = parseFloat( $(this).data('map-center-coords').split(',')[1] );
		} else {
			centerLat = 0;
			centerLng = 0;
		}
		
		zoom = $(this).data('map-zoom') || 8;
		
		if ((typeof settings.mapStyle !== 'undefined') || (settings.mapStyle === false))  {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				
				styles: settings.mapStyle
			});
		} else {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false
			});
		}
		

		if (typeof $(this).data('map-marker-coords') != 'undefined') {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}
			
			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});
			
			if (typeof $(this).data('map-marker-url') != 'undefined') {
				marker.setIcon($(this).data('map-marker-url'));
			}
		}
		
		$(this).siblings('[data-map-marker]').each(function () {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}
			
			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});
			
			if (typeof $(this).data('map-marker-url') != 'undefined') {
				marker.setIcon($(this).data('map-marker-url'));
			}
		});
		
		if (typeof $(this).data('map-directions') != 'undefined') {
			var directionsService  = new google.maps.DirectionsService();
			var directionsRenderer = new google.maps.DirectionsRenderer();
			directionsRenderer.setMap(map);
			directionsRenderer.setPanel($('#map-directions-data').get(0));
			
			var directionsTo = $(this).data('map-directions-destination');
			
			$('#frmPostcode').on('submit', function() {
				var directionsFrom = jQuery.trim(jQuery('#tbPostcode').val());
				var directionsRequest = {
					origin: directionsFrom,
					destination: directionsTo,
					travelMode: google.maps.TravelMode.DRIVING,
					unitSystem: google.maps.UnitSystem.IMPERIAL
				};
				
				directionsService.route(directionsRequest, function(result, status){        
			    	if (status == google.maps.DirectionsStatus.OK) {
						marker.setMap(null);
						directionsRenderer.setDirections(result);
			    	} else {
				    	alert('Location "' + directionsFrom + '" not found.');
			    	}
				});
				
				return false;
			});
		}
	});
}

var initDetail = function () {
	$('.detail__features-toggle').on('click', function (e) {
		e.preventDefault();
		
		// Toggle the second list of features
		$(this).prev('.detail__features-list--additional').slideToggle();
		
		// Toggle the read more/less button text
		if ( $(this).text() === $(this).data('more-text') ) {
			$(this).text($(this).data('less-text'));
		} else {
			$(this).text($(this).data('more-text'));
		}
	});
	
	// Make sure the second list is visible again when screen size increases to medium
	if ( $('.detail__features').length > 0 ) {
		$(window).on('resize', function () {
			if (Foundation.MediaQuery.atLeast('medium')) {
				$('.detail__features-list--additional').show();
			}
		});
		
		// Make the price larger
		// $('.detail__rate').html(wrapPrice($('.detail__rate').html(), '<big>', '</big>'));
	}
	
}

var initOtherRooms = function () {
	// Custom nav for the carousel
	$('.other-rooms__nav button').on('click', function () {
		$(this).parents('.other-rooms').find('.other-rooms__carousel').slick('slickGoTo', $(this).data('index'));
	});
	
	// Update the nav when the slide changes
	$('.other-rooms__carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		$(event.target).parents('.other-rooms').find('li.current').removeClass('current');
		$(event.target).parents('.other-rooms').find('li').eq(nextSlide).addClass('current');
	});
	
	$('.other-rooms__carousel').slick(settings.otherRoomsCarousel);
}



var initForms = function () {
	$('.form').validate();
}



var initTerms = function () {
	$('.terms__content-more').accordiom({
		showFirstItem: false,
		buttonBelowContent: true,
		beforeChange: function () {
			if ( $(this).hasClass('on') ) {
				$(this).text( $(this).data('more-text') );
			} else {
				$(this).text( $(this).data('less-text') );
			}
		}
	});
}



var initGallery = function () {
	$('.gallery__item a').on('click', function () {
		// Dynamically load the gallery slider into a reveal popup	
		var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery__modal-image"><img src="{{src}}"><button class="gallery__modal-prev">Previous</button><button class="gallery__modal-next">Next</button></div><div class="gallery__modal-content grid-x grid-margin-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div><div class="gallery__modal-cta cell small-12 medium-6">{{cta}}</div></div></div>';
		
		modal = modal.replace('{{src}}', $(this).attr('href'));
		modal = modal.replace('{{caption}}', $(this).data('title') || '');
		
		var cta = $(this).data('description') || '';
		if ( ( typeof $(this).data('book-link') !== 'undefined' ) && ( $(this).data('book-link') !== '' ) ) {
			cta += '<a href="' + $(this).data('book-link') + '" class="button button--green" target="_blank">' + ($(this).data('book-title') || 'Book Now') + '</a>';
		}
		modal = modal.replace('{{cta}}', cta);
		
		var $modal = modal;
		
		$('body').append($modal);
		
		$('#gallery__modal').foundation().on('closed.zf.reveal', function () {
			$('#gallery__modal').remove();
		});
		
		$('#gallery__modal').foundation('open');
		
		return false;
	});
	
	refreshMasonry();
	$('.gallery').on('filter-changed', function () {
		setTimeout(refreshMasonry, 600);
	});
	
	Foundation.onImagesLoaded($('.gallery__item img'), refreshMasonry);
	
	function refreshMasonry() {
		$('.gallery__grid').masonry({
			columnWidth: '.gallery__item-sizer',
			itemSelector: '.gallery__item',
			percentPosition: true,
			horizontalOrder: true
		});
	};
	
	// Remove the transition class after loading to avoid jumpy thumbnails
	setTimeout(function () {
		$('.gallery__item').removeClass('transition--2000');
	}, 2000);
	
	
	
	// Remove empty filter buttons (if not handled by the plugin)
	$('.filter__buttons button').each(function () {
		if ( $(this).text() == '' ) {
			$(this).remove();
		}
	});
}



var initTreatments = function () {
	$('.treatments__nav').accordiom({
		showFirstItem: false
	});
	
	$('.treatments__nav a').on('click', function () {
		$('html, body').animate({
			scrollTop: $('a[name="' + $(this).attr('href').replace('#', '') + '"]' ).offset().top - $('.header').outerHeight() - 20
		}, 1000);
		
		return false;
	});
}



var initAccommodation = function () {
	$('.accommodation__views button').on('click', function () {
		if ( $(this).hasClass('current') ) {
			return;
		}
		
		// Switch the button current states
		$('.accommodation__views button.current').removeClass('current')
		$(this).addClass('current');
		
		// Change the classes to display the selected view
		var elementList = ['accommodation__item', 'accommodation__image', 'accommodation__content', 'accommodation__details', 'accommodation__price', 'accommoddation__cta'];
		
		var dataClassAttribute = 'list-class'; // list is the default mode
		if ( $(this).hasClass('accommodation__views-grid-button') ) {
			dataClassAttribute = 'grid-class';
			
			$('.accommodation__items').removeClass('accommodation__items--list-view').addClass('accommodation__items--grid-view');
		} else {
			$('.accommodation__items').removeClass('accommodation__items--grid-view').addClass('accommodation__items--list-view');
		}
		
		for (var i = 0; i < elementList.length; i++) {
			$('.' + elementList[i]).each(function () {
				$(this).attr('class', elementList[i] + ' ' + $(this).data(dataClassAttribute));
			});
		}
	});
}




var initOffCanvas = function () {
	$('.off-canvas__nav-primary > ul > li').each(function () {
		// Automatically add the "Overview" links to the landing page
		if ( $(this).find('ul').length > 0 ) {
			var overviewLink = $(this).find('a').first().attr('href');
			$(this).find('ul').prepend('<li class="offcanvas__overview-link"><a href="' + overviewLink + '">Overview</a></li>');
		}
	});
	
	
	$('.off-canvas__nav-primary > ul > li > a').on('click', function () {
		if ( $(this).next('.off-canvas__nav-sub').length > 0 ) { // only trigger if there are sub pages to show
			if ( $(this).parent().hasClass('open') ) {
				// second click of main item allows clicking directly through
				return true;
			} else {
				// close any other open items
				$('.off-canvas__nav-primary > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();
				
				// and open the new one
				$(this).parent().addClass('open');
				$(this).next().slideDown();
			}
			return false; // prevent navigating
		}
	});
}






var initPopup = function () {
	if ($('#popup').length > 0) {
		var popupDelay = $('#popup').data('popup-delay') || 1000; // Default delay to 1000ms (1 second) if no value provided
		var popupKeepHiddenFor = $('#popup').data('popup-keep-hidden-for-n-days') || 0;

		console.log('Popup Delay:', popupDelay);
		console.log('Popup Keep Hidden For:', popupKeepHiddenFor);

		if ( 
			(!Cookies.get('hasSeenPopup')) || 
			(location.hostname.indexOf('localhost') > -1) || 
			(location.hostname.indexOf('projects') > -1) 
		) {
			// Set a timeout to delay the popup display
			setTimeout(function() {
				$('#popup').foundation('open');

				// Set the cookie to prevent the popup from reappearing if applicable
				if (popupKeepHiddenFor > 0) {
					Cookies.set('hasSeenPopup', 'true', { expires: popupKeepHiddenFor });
				}
			}, Math.max(popupDelay, 0)); // Ensure delay is a positive integer
		}
	}
}





var initLogoCarousel = function () {
	$('.logo-carousel__inner').slick(settings.logoCarousel);
}



var initHome = function () {
	$('.home__row-slider').slick(settings.homeSlider);
}